<template>
  <el-dialog title="微信支付"
             :close-on-click-modal="false"
             :visible.sync="visible"
             class="dev-dialog dev-dialog-center"
             lock-scroll width="800px" height="600px">
    <div class="modal-box">
      <div class="modal-left">
        <p><span>请使用 </span><span class="orange">微信 </span><i class="icon icon-qrcode"></i><span class="orange"> 扫一扫</span><br>扫描二维码支付</p>
        <div class="modal-qr">
          <div class="modal-qrcode">
            <vue-qr :text="qrCodeValue"></vue-qr>
          </div>
          <div class="model-info">
            <img src="https://x.dscmall.cn/themes/ecmoban_dsc2017/images/sj.png" class="icon-clock">
            <span>二维码有效时长为2小时, 请尽快支付</span>
          </div>
        </div>
      </div>
      <div class="modal-right">
        <img src="https://x.dscmall.cn/themes/ecmoban_dsc2017/images/weixin-qrcode.jpg">
      </div>
      <div style="clear: both"/>
    </div>
  </el-dialog>
</template>
<script>
import {wxQrCodePay} from "@/api/mall/pay";
import VueQr from 'vue-qr'
export default {
  name: "DistrictSelect",
  components:{
    VueQr
  },
  data() {
    return {

      visible: false,
      qrCodeValue : null
    }
  },
  props: {
    value: {},
  },

  methods: {

    init() {
      this.visible = true

      wxQrCodePay({
        Body: "测试微信支付",
        OutTradeNo: "20210101010101",
        TotalFee: "1",
        SpbillCreateIp: "127.0.0.1",
        NotifyUrl: "http://www.baidu.com",
        TradeType: "NATIVE"
      }).then(res => {
        console.log(res)

        this.qrCodeValue = res.data.CodeUrl
      })

      // if(id){
      //
      // }
    },
  }
}
</script>
<style scoped>

</style>